import React from "react";

import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import { Grid } from "@mui/material";
import Header from "./Header";
import AppBar from "./AppBar";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import MobileAppBar from "./MobileAppBar";

const theme = createTheme({
  typography: {
    fontFamily: "Alegreya Sans SC, Trebuchet MS",
    fontSize: 16,
    fontWeight: 100,
  },
  palette: {
    primary: { main: "#5B7195" },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: "Economica",
        },
      },
    },
  },
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sizing: {
        xs: {
          usedHeight: 60,
          usedWidth: 30,
          footer: 25,
        },
        sm: {
          usedHeight: 229.88,
          usedWidth: 0,
          footer: 67.88,
        },
        vh: window.innerHeight,
        vw: window.innerWidth,
      },
      dimensionTimeout: null,
    };
  }

  handleDimensions = async () => {
    if (this.state.dimensionTimeout) {
      clearTimeout(this.state.dimensionTimeout);
    }
    this.setState({
      dimensionTimeout: setTimeout(() => {
        const innerHeight = window.innerHeight;
        const innerWidth = window.innerWidth;
        const sizing = { ...this.state.sizing };
        sizing.vh = innerHeight;
        sizing.vw = innerWidth;
        this.setState({ sizing });
      }, 500),
    });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleDimensions);
  }

  render() {
    const { sizing } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Grid
          direction="column"
          container
          spacing={0}
          sx={{
            width: {
              xs: `${sizing.vw - sizing.xs.usedWidth}px`,
              sm: `${sizing.vw - sizing.sm.usedWidth}px`,
            },
            minHeight: {
              xs: `${sizing.vh - sizing.xs.footer}px`,
              sm: `${sizing.vh - sizing.sm.footer}px`,
            },
            backgroundColor: "white",
          }}
        >
          <Header sizing={sizing} />
          <AppBar sizing={sizing} />
          <Outlet context={{ sizing }} />
        </Grid>
        <Footer sizing={sizing} />
        <MobileAppBar sizing={sizing} />
      </ThemeProvider>
    );
  }
}

export default App;
