import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
// import reportWebVitals from "./reportWebVitals";
import RequestQuote from "./RequestQuote";
import CustomPlans from "./StaticPages/CustomPlans.js";
import HomePage from "./HomePage";
import Gallery from "./Gallery";
import Admin from "./Admin";
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import { useOutletContext } from "react-router-dom";

const OutletProvider = ({ children }) => {
  const contextProps = useOutletContext();

  return typeof children === "function" ? children(contextProps) : children;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route
        index={true}
        element={
          <OutletProvider>{(props) => <HomePage {...props} />}</OutletProvider>
        }
      />
      <Route
        path="/gallery"
        element={
          <OutletProvider>{(props) => <Gallery {...props} />}</OutletProvider>
        }
      />
      <Route
        path="/pricing"
        element={
          <OutletProvider>{(props) => <div {...props} />}</OutletProvider>
        }
      />
      <Route
        path="/requestquote"
        element={
          <OutletProvider>
            {(props) => <RequestQuote {...props} />}
          </OutletProvider>
        }
      />
      <Route
        path="/customplans"
        element={
          <OutletProvider>
            {(props) => <CustomPlans {...props} />}
          </OutletProvider>
        }
      />
      <Route
        path="/additions"
        element={
          <OutletProvider>{(props) => <div {...props} />}</OutletProvider>
        }
      />
      <Route
        path="/renderings"
        element={
          <OutletProvider>{(props) => <div {...props} />}</OutletProvider>
        }
      />
      <Route
        path="/stockplans"
        element={
          <OutletProvider>{(props) => <div {...props} />}</OutletProvider>
        }
      />
      <Route
        path="/services"
        element={
          <OutletProvider>{(props) => <div {...props} />}</OutletProvider>
        }
      />
      <Route
        path="/aboutus"
        element={
          <OutletProvider>{(props) => <div {...props} />}</OutletProvider>
        }
      />
      {/*<Route*/}
      {/*path="/admin"*/}
      {/*element={*/}
      {/*<OutletProvider>{(props) => <Admin {...props} />}</OutletProvider>*/}
      {/*}*/}
      {/*/>*/}
      {/* ... etc. */}
    </Route>
  )
);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
