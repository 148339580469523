import React from "react";

import { Grid, Typography, Button, Paper } from "@mui/material";
import TopImage from "../images/homepage/TopImage.png";
import PhoneImage from "../images/homepage/Phone.jpeg";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import VillaIcon from "@mui/icons-material/Villa";
import HardwareIcon from "@mui/icons-material/Hardware";
import DesignServicesIcon from "@mui/icons-material/DesignServices";

export const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  lineHeight: "90%",
  padding: "8px 30px",
  fontSize: "18px",
  opacity: "80%",
  minHeight: "48.38px",
  // transform: "scaleX(1.2)",
}));

export const InsideSpans = (props) => (
  <span
    style={{
      textDecorationLine: "underline",
      textUnderlineOffset: "-11px",
      transform: "scaleX(1.2)",
    }}
  >
    <span
      style={{
        position: "absolute",
        textDecorationLine: "underline",
        textUnderlineOffset: 1,
      }}
    >
      {props.children}
    </span>
    {props.children}
  </span>
);

export const PhoneButton = (props) => (
  <StyledButton
    sx={{
      position: "absolute",
      left: `34px`,
      top: props.top,
      borderRadius: "0 4px 4px 0",
    }}
    size="large"
    variant="contained"
    component={Link}
    to={props.to}
    onClick={props.onClick}
  >
    <InsideSpans>{props.spanlabels}</InsideSpans>
    <Paper
      square
      sx={{
        position: "absolute",
        left: "-34px",
        top: 0,
        height: "100%",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#4b4b4b",
        color: "white",
        minWidth: "30px",
        textAlign: "right",
        paddingLeft: "5px",
      }}
    >
      {props.icon}
    </Paper>
    <div
      style={{
        position: "absolute",
        left: "-.5px",
        top: 0,
        width: 0,
        height: 0,
        backgroundColor: "transparent",
        borderTop: "24px solid transparent",
        borderBottom: "24px solid transparent",
        borderLeft: "15px solid #4b4b4b",
      }}
    />
  </StyledButton>
);

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Grid container spacing={0} item xs={12} alignItems="center">
        <Grid
          item
          xs={12}
          sx={{
            display: { xs: "none", sm: "block" },
            justifyContent: "center",
            paddingTop: "10px",
            width: { xs: "100%", sm: "722px" },
            height: { xs: "100%", sm: "164px" },
          }}
        >
          <img
            src={`${TopImage}`}
            width="100%"
            height="100%"
            style={{ objectFit: "contain" }}
            alt="Black + White Rendering"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
            paddingBottom: "15px",
          }}
        >
          <Typography variant="h4" style={{ fontWeight: "300" }}>
            Modline Design
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ lineHeight: { xs: "80%", sm: "inherit" } }}
          >
            - Architecture - Planning - Design -
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ lineHeight: { xs: "80%", sm: "inherit" } }}
          >
            37 N Main St. Farmington, UT 84025
          </Typography>
        </Grid>
        <Grid
          container
          spacing={0}
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          className="MiddlePage"
          sx={{
            minHeight: "412px",
            textAlign: "center",
            display: { xs: "none", sm: "flex" },
          }}
        >
          <Grid item xs={2} />
          <Grid item xs={12} sm={2}>
            <StyledButton
              size="large"
              variant="contained"
              component={Link}
              // to="/stockplans"
              to="/gallery"
            >
              <InsideSpans>
                Gallery
                {/*Browse <br /> Stock*/}
              </InsideSpans>
            </StyledButton>
          </Grid>
          {/*<Grid item xs={12} sm={4}>*/}
          {/*<StyledButton*/}
          {/*size="large"*/}
          {/*variant="contained"*/}
          {/*component={Link}*/}
          {/*to="/additions"*/}
          {/*>*/}
          {/*<InsideSpans>*/}
          {/*Additions &<br />*/}
          {/*Remodels*/}
          {/*</InsideSpans>*/}
          {/*</StyledButton>*/}
          {/*</Grid>*/}
          <Grid item xs={12} sm={2}>
            <StyledButton
              size="large"
              variant="contained"
              component={Link}
              to="/customplans"
            >
              <InsideSpans>
                Custom <br /> Plans
              </InsideSpans>
            </StyledButton>
          </Grid>
          <Grid item xs={2} />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: {
              xs: "flex",
              sm: "none",
            },
            position: "relative",
          }}
        >
          <img
            src={`${PhoneImage}`}
            width="100%"
            style={{ objectFit: "contain" }}
            alt="Black + White Rendering"
          />
          <PhoneButton
            // to="/stockplans"
            to="/gallery"
            top="calc(25% - 14px)"
            spanlabels={
              <span>
                Gallery
                {/*Browse <br /> Stock*/}
              </span>
            }
            icon={<VillaIcon />}
          />
          {/*<PhoneButton*/}
          {/*to="/additions"*/}
          {/*top="calc(50% - 14px)"*/}
          {/*spanlabels={*/}
          {/*<span>*/}
          {/*Additions &<br />*/}
          {/*Remodels*/}
          {/*</span>*/}
          {/*}*/}
          {/*icon={<HardwareIcon />}*/}
          {/*/>*/}
          <PhoneButton
            to="/customplans"
            top="calc(75% - 14px)"
            spanlabels={
              <span>
                Custom <br /> Plans
              </span>
            }
            icon={<DesignServicesIcon />}
          />
        </Grid>
      </Grid>
    );
  }
}

export default HomePage;
