import React from "react";
import { alpha } from "@mui/material/styles";
import { Grid, Tabs, Tab, Menu, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import { ExpandMore } from "@mui/icons-material";

const CustomTab = (props) => (
  <Tab
    component={props.component || Link}
    {...props}
    sx={{
      opacity: 1,
      color: props.color || "#9aa2ae",
      "&:hover": {
        opacity: 0.5,
        backgroundColor: alpha("#fff", 0.1),
      },
    }}
  />
);

class AppBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { tab: false };
  }

  handleChange = (event, value) => {
    if (value !== 2) {
      this.setState({ tab: value, anchorEl: null });
    }
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleNav = () => {
    this.setState({ anchorEl: null, tab: 2 });
  };

  render() {
    return (
      <Grid
        item
        xs={12}
        style={{ backgroundColor: "#4b4b4b", width: "100%" }}
        display={{ xs: "none", sm: "block" }}
      >
        <Tabs
          centered
          value={this.state.tab}
          onChange={this.handleChange}
          indicatorColor="#9aa2ae"
        >
          <CustomTab to="gallery" label="Gallery" />
          {/*<CustomTab*/}
          {/*component="a"*/}
          {/*label={*/}
          {/*<div>*/}
          {/*Services*/}
          {/*<ExpandMore sx={{ fontSize: 14 }} />*/}
          {/*</div>*/}
          {/*}*/}
          {/*onClick={this.handleClick}*/}
          {/*/>*/}
          <CustomTab
            to="customplans"
            label={
              <div>
                Custom
                <br /> Plans{" "}
              </div>
            }
          />
          {/*<CustomTab to="pricing" label="Pricing" />*/}
          <CustomTab
            to="requestquote"
            label={
              <div>
                Request
                <br /> Quote{" "}
              </div>
            }
            color="#5B7195"
          />
        </Tabs>
        <Menu
          id="nav-menu"
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
          sx={{
            ".MuiPaper-root": {
              borderRadius: 0,
              backgroundColor: alpha("#4b4b4b", 0.8),
            },
          }}
        >
          <MenuItem
            sx={{ opacity: "1 !important", color: "white" }}
            component={Link}
            to="customplans"
            onClick={this.handleNav}
          >
            Custom Plans
          </MenuItem>
          <MenuItem
            sx={{ opacity: "1 !important", color: "white" }}
            component={Link}
            to="additions"
            onClick={this.handleNav}
          >
            Additions + Remodels
          </MenuItem>
          <MenuItem
            sx={{ opacity: "1 !important", color: "white" }}
            component={Link}
            to="renderings"
            onClick={this.handleNav}
          >
            Renderings
          </MenuItem>
          <MenuItem
            sx={{ opacity: "1 !important", color: "white" }}
            component={Link}
            to="stockplans"
            onClick={this.handleNav}
          >
            Stock Plans
          </MenuItem>
        </Menu>
      </Grid>
    );
  }
}

export default AppBar;
