import React from "react";

import { Grid, Typography } from "@mui/material";
import ModlineIcon from "../images/homepage/Icon.png";
import { Link } from "react-router-dom";

const IconImage = (props) => (
  <img src={`${ModlineIcon}`} alt="Modline Logo" {...props} />
);

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Grid
        container
        spacing={0}
        item
        alignItems="center"
        xs={12}
        style={{
          backgroundColor: "#262626",
          borderBottom: ".1px white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 9002,
          width: `${this.props.sizing.vw}px`,
        }}
      >
        <Grid item xs sx={{ height: { xs: "35px", sm: "98px" } }} />
        <Grid
          item
          xs={1}
          sx={{
            display: { xs: "none", sm: "block" },
          }}
          to="/"
          style={{ height: "62px", width: "62px" }}
          component={Link}
        >
          <IconImage
            width="100%"
            height="100%"
            style={{ objectFit: "contain" }}
          />
        </Grid>
        <Grid
          item
          xs={10}
          component={Typography}
          variant="h5"
          sx={{
            color: "#9aa2ae",
            fontSize: { xs: "15px", sm: "25px" },
            letterSpacing: { xs: ".5px", sm: "6px" },
            textAlign: "center",
            transform: "scaleY(0.5)",
          }}
        >
          DESIGN &nbsp;|&nbsp; CREATE &nbsp;|&nbsp; INSPIRE
        </Grid>
        <Grid item xs={0} sm={1} />
        <Grid
          item
          xs={1}
          sx={{
            display: { xs: "block", sm: "none" },
            textAlign: "right",
          }}
          to="/"
          style={{ height: "25px", width: "25px" }}
          component={Link}
        >
          <IconImage
            width="100%"
            height="100%"
            style={{ objectFit: "contain" }}
          />
        </Grid>
      </Grid>
    );
  }
}

export default Header;
