import axios from "axios";
import config from "./config";

export default axios.create({
  baseURL:
    config.env === "production"
      ? "https://api.modlinedesign.com"
      : "http://38.70.253.219:9000/",
  // headers: {'Authorization': `Bearer ${ID_TOKEN}`}
});
