import React from "react";

export default {
  informationgathering: {
    title: (
      <span>
        Information
        <br />
        Gathering
      </span>
    ),
    link: "informationgathering",
    header: (
      <span>
        Phase 1. <br />
        <br />
        Information Gathering
        <br />
        <br />
      </span>
    ),
    body: (
      <span>
        In our initial meeting, the goal is to sit down and outline the
        project’s details. The design process can be overwhelming at first,
        every project has so many decisions that need to be made. At this point,
        our professional designers will help funnel your thoughts into an
        organized packet of parameters that will stand as the foundation for the
        entire project. Some topics that are usually covered in this meeting
        are:
        <br />
        <br />
        a. Architecture and Layout
        <br />
        b. Site Conditions
        <br />
        c. Budget
        <br />
        <br />
      </span>
    ),
  },
  designing: {
    title: <span>Designing</span>,
    link: "designing",
    header: (
      <span>
        Phase 2.
        <br />
        <br />
        Designing
        <br />
        <br />
      </span>
    ),
    body: (
      <span>
        After our initial meeting where we determine all of the project’s
        parameters, we can discuss the timeframe and get started on drafting up
        a set of preliminary plans. Preliminary plans are a starting point for
        us to discuss likes and dislikes to help tune your plan into the perfect
        plan for you and your needs. Timeframes can vary depending on the
        project’s size and complexity.
        <br />
        <br />
      </span>
    ),
  },
  adjustments: {
    title: <span>Adjustments</span>,
    link: "adjustments",
    header: (
      <span>
        Phase 3.
        <br />
        <br />
        Adjustments
        <br />
        <br />
      </span>
    ),
    body: (
      <span>
        After a set of preliminary plans is drafted up and ready to review, we
        will get back together to further discuss items that we may not have
        thought about initially, or that we would like to make adjustments to.
        Our drafters will make corrections to the plans as many times as
        necessary so that you have every opportunity to ensure your plan is
        exactly as you want it.
        <br />
        <br />
      </span>
    ),
  },
  finalization: {
    title: <span>Finalization</span>,
    link: "finalization",
    header: (
      <span>
        Phase 4.
        <br />
        <br />
        Finalization
        <br />
        <br />
      </span>
    ),
    body: (
      <span>
        As soon as you are happy with the plans, we will finish the plans and
        prepare them for engineering. At this stage, you have made all of the
        decisions you need to make – sit back and let us work our magic!
        Creating a clean, workable, easy to read set of construction documents
        is an artform in-and-of itself. Our team has over 50 years of combined
        experience working with builders and construction professionals that
        helps our plans stand out compared to our competitors. Our plans are
        specifically designed in a way to help make them easy to understand for
        you, whilst being detail rich to convey the grand vision to the
        individuals who will be building your project. Every part of the plan
        from the lowest footings to the peak of the highest roofline is
        meticulously thought-through, reviewed, revised, and reviewed again to
        ensure that the product you receive from us is of the highest possible
        quality.
        <br />
        <br />
      </span>
    ),
  },
  permitting: {
    title: <span>Permitting</span>,
    link: "permitting",
    header: (
      <span>
        Phase 5.
        <br />
        <br />
        Permitting
        <br />
        <br />
      </span>
    ),
    body: (
      <span>
        Your plans are now complete, and you are ready to apply for a building
        permit. You will receive digital PDF versions of your construction
        documents, wet stamped by a licensed engineer, ready to build from. You
        will also receive two copies each of small and large sets of prints,
        which you can use on-site, or keep for your personal use. You are free
        to print additional sets at your leisure directly from the final PDF.
        Permit application requirements vary between municipalities. Most cities
        have information online you can find that explains what your exact
        requirements will be to apply for a building permit. All projects that
        require a building permit will require a set of engineered (stamped)
        plans and calculations, and most will require a site plan. After
        submitting for a building permit, the city may require that you make
        adjustments to your plans in order to clarify items as they relate to
        building code or local regulations. We include revisions to plans on
        account of city code revisions free of charge.
        <br />
        <br />
      </span>
    ),
  },
  construction: {
    title: <span>Construction</span>,
    link: "construction",
    header: (
      <span>
        Phase 6.
        <br />
        <br />
        Construction
        <br />
        <br />
      </span>
    ),
    body: (
      <span>
        With your building permit accepted you are ready for construction! We
        encourage you to maintain contact with us throughout your build process
        if you have any questions or concerns in regards to your project, we are
        available to provide clarification for you or your builder.
      </span>
    ),
  },
};
