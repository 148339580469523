import React from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Tooltip,
  Snackbar,
} from "@mui/material";
import Captcha from "react-google-recaptcha";
import BackendAxios from "../BackendAxios";

import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class RequestQuote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      phone: "",
      email: "",
      message: "",
      token: "",
      loading: false,
      status: "Status Text",
      statusOpen: false,
      statusState: "error",
    };
  }

  closeStatus = () => {
    this.setState({ statusOpen: false, status: "" });
  };

  sendEmail = () => {
    let { name, phone, email, message, token } = this.state;
    // Has to have all
    if (!name || !phone || !email || !message || !token) {
      return;
    }
    if (!this.checkEmail()) {
      // Invalid Email
      return;
    }
    if (!this.checkPhone()) {
      // Invalid Phone
      return;
    }
    this.setState({ loading: true });
    BackendAxios.post(`/email/send`, {
      name,
      phone,
      email,
      message,
      token,
    })
      .then((res) => {
        setTimeout(() => {
          this.setState({ loading: false });
        }, 1000);
        this.setState({
          name: "",
          phone: "",
          email: "",
          message: "",
          statusOpen: true,
          status: "Your request has been sent.",
          statusState: "success",
        });
      })
      .catch((e) => {
        setTimeout(() => {
          this.setState({ loading: false });
        }, 1000);
        this.setState({
          name: "",
          phone: "",
          email: "",
          message: "",
          statusOpen: true,
          status: "Failed to send.",
          statusState: "error",
        });
      });
  };

  checkEmail = () => {
    let { email } = this.state;
    // returns if email is valid
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  };

  checkPhone = () => {
    let { phone } = this.state;
    // returns if phone is valid
    return /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(phone);
  };

  onChange = (prop) => (event) => {
    let value = event.target.value;
    if (prop === "phone") {
      value = value.replace(/\D/g, "");
    }
    this.setState({ [prop]: value });
  };

  captchaChange = (value) => {
    this.setState({ token: value });
  };

  shouldBeDisabled = () => {
    return Boolean(
      !this.state.name ||
        !this.state.phone ||
        !this.checkPhone() ||
        !this.state.email ||
        !this.checkEmail() ||
        !this.state.message ||
        !this.state.token ||
        this.state.loading
    );
  };

  render() {
    return (
      <Grid
        container
        spacing={0}
        item
        xs={12}
        sx={{
          padding: { xs: "10px", sm: "20px 0" },
        }}
        justifyContent="space-evenly"
      >
        <Grid
          item
          xs={12}
          md={4}
          container
          direction="column"
          spacing={1}
          alignItems="center"
          order={{ xs: 2, sm: 1 }}
        >
          <Grid item style={{ textAlign: "center", color: "#9aa2ae" }}>
            <Typography variant="h5">Location</Typography>
          </Grid>
          <Grid item>
            <iframe
              title="Address Map"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12048.07004263775!2d-111.8879664!3d40.9810974!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xece39623c5162e23!2sModline%20Design!5e0!3m2!1sen!2sus!4v1669663165647!5m2!1sen!2sus"
              width="350"
              height="300"
              style={{ border: 0, maxWidth: "100%" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </Grid>
          <Grid item style={{ paddingBottom: "10px", color: "#9aa2ae" }}>
            <Typography
              variant="subtitle2"
              style={{
                fontWeight: "bold",
                lineHeight: "150%",
                color: "#262626",
              }}
            >
              Modline Design
            </Typography>
            <Typography
              variant="caption"
              style={{
                lineHeight: "100%",
                textDecoration: "none",
                color: "inherit",
              }}
              href="https://www.google.com/maps/place/Modline+Design/@40.9811014,-111.8901551,17z/data=!3m1!4b1!4m5!3m4!1s0x8752ff632539f637:0xece39623c5162e23!8m2!3d40.9810974!4d-111.8879664"
              component="a"
            >
              37 N Main Street. Farmington Utah, 84025
            </Typography>
            <br />
            <Typography
              variant="caption"
              style={{
                lineHeight: "100%",
                textDecoration: "none",
                color: "inherit",
              }}
              href="tel:+1-801-839-5568"
              component="a"
            >
              (801) 839-5568
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          spacing={0}
          md={4}
          xs={12}
          style={{
            textAlign: "center",
            color: "#9aa2ae",
            maxWidth: "450px",
          }}
          order={{ xs: 1, sm: 2 }}
        >
          <Grid item xs={12}>
            <Typography variant="h5">Send Message</Typography>
            <br />
            <Typography variant="caption">
              We would love to hear from you. <br /> <br />
              <div style={{ lineHeight: "100%" }}>
                Please use the form below to send us a comment or ask a
                question. <br />
                We will reply as soon as possible.
              </div>
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "20px", textAlign: "left" }}>
            <TextField
              sx={{ marginBottom: "10px" }}
              label="Name"
              required
              variant="outlined"
              fullWidth
              size="small"
              onChange={this.onChange("name")}
              value={this.state.name}
            />
            <TextField
              error={this.state.phone !== "" && !this.checkPhone()}
              helperText={
                this.state.phone !== "" && !this.checkPhone()
                  ? "Please input a valid 10 digit phone number."
                  : ""
              }
              sx={{ marginBottom: "10px" }}
              label="Phone"
              required
              variant="outlined"
              fullWidth
              size="small"
              onChange={this.onChange("phone")}
              value={this.state.phone}
            />
            <TextField
              error={this.state.email !== "" && !this.checkEmail()}
              helperText={
                this.state.email !== "" && !this.checkEmail()
                  ? "Please input a valid email."
                  : ""
              }
              sx={{ marginBottom: "10px" }}
              required
              label="Email"
              variant="outlined"
              fullWidth
              size="small"
              onChange={this.onChange("email")}
              value={this.state.email}
            />
            <TextField
              sx={{ marginBottom: "10px" }}
              label="Your Message"
              required
              variant="outlined"
              multiline
              fullWidth
              size="small"
              onChange={this.onChange("message")}
              rows={5}
              value={this.state.message}
            />
            <Captcha
              sitekey="6Lc750YjAAAAAKG8bUl_LWfG2HlMpoMMBKIIFQNA"
              onChange={this.captchaChange}
            />
            <Tooltip
              title="Please finish filling out the form."
              disableHoverListener={!this.shouldBeDisabled()}
              disableFocusListener={!this.shouldBeDisabled()}
              disableTouchListener={!this.shouldBeDisabled()}
              disableInteractive={!this.shouldBeDisabled()}
            >
              <span>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#4b4b4b",
                    color: "white",
                    marginTop: "10px",
                  }}
                  onClick={this.sendEmail}
                  disabled={this.shouldBeDisabled()}
                >
                  {this.state.loading ? (
                    <CircularProgress
                      sx={{
                        color: "#4b4b4b",
                      }}
                      size={25}
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
        <Snackbar
          open={this.state.statusOpen}
          autoHideDuration={6000}
          onClose={this.closeStatus}
          sx={{
            bottom: { xs: 23, md: 180 },
            right: { xs: 23, md: 90 },
          }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {this.state.statusState === "success" ? (
            <Alert
              onClose={this.closeStatus}
              severity="success"
              sx={{ width: "100%" }}
            >
              {this.state.status}
            </Alert>
          ) : (
            <Alert
              severity="error"
              onClose={this.closeStatus}
              sx={{ width: "100%" }}
            >
              {this.state.status}
            </Alert>
          )}
        </Snackbar>
      </Grid>
    );
  }
}

export default RequestQuote;
