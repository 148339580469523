import React from "react";
import {
  Grid,
  TextField,
  Card,
  CardHeader,
  CardContent,
  CardActions,
} from "@mui/material";
import BackendAxios from "../BackendAxios";
import { StyledButton, InsideSpans } from "../HomePage/index";

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
    };
  }

  refreshGallery = async () => {
    let galleryImages = await BackendAxios.get(
      `/files/refreshgallery?password=${this.state.password}`
    ).then((res) => {
      return res.data;
    });
  };

  onChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  };

  render() {
    return (
      <Grid
        item
        xs={12}
        container
        spacing={0}
        sx={{ textAlign: "center", padding: "16px" }}
      >
        <Grid item xs={6}>
          <Card>
            <CardHeader title="Pull from DropBox" />
            <CardContent>
              <TextField
                label="Enter Password"
                value={this.state.password}
                onChange={this.onChange("password")}
              />
              <br />
              <StyledButton
                sx={{
                  marginTop: "15px",
                  borderRadius: "50%",
                  color: "white",
                  opacity: ".5",
                  backgroundColor: "#262626",
                  height: 64,
                  width: 64,
                  direction: false,
                }}
                disableRipple
                variant="contained"
              >
                <InsideSpans>Submit</InsideSpans>
              </StyledButton>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default Admin;
