import React from "react";
import {
  Grid,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
} from "@mui/material";
import ProcessImage from "../images/customplans/CustomPlans.png";
import Info from "@mui/icons-material/Info";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { PhoneButton, InsideSpans, StyledButton } from "../HomePage/index";
import { Link } from "react-router-dom";

import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

import CustomPlansText from "../Text/CustomPlans";

const titleMap = [
  "informationgathering",
  "designing",
  "adjustments",
  "finalization",
  "permitting",
  "construction",
];

class CustomPlans extends React.Component {
  constructor(props) {
    super(props);
    this.state = { informationDialogOpen: false, informationDialogHTML: null };
  }

  closeDialog = () => {
    this.setState({ informationDialogOpen: false });
  };

  loadContent = (title) => () => {
    this.setState({
      informationDialogHTML: (
        <span>
          {CustomPlansText[title].header} {CustomPlansText[title].body}
        </span>
      ),
      informationDialogOpen: true,
    });
  };

  render() {
    return (
      <Grid
        item
        xs={12}
        container
        sx={{
          paddingBottom: { xs: "5px" },
          paddingTop: { xs: "0", sm: "25px" },
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={5}
          sx={{ textAlign: "right", position: { xs: "relative", sm: "block" } }}
        >
          <img src={ProcessImage} alt="Process of drawing custom plans" />
          <Typography
            component="span"
            sx={{ zIndex: 9000 }}
            display={{ xs: "block", sm: "none" }}
          >
            {Object.keys(CustomPlansText).map((title, index) => (
              <PhoneButton
                key={`PhoneButton${index}`}
                onClick={this.loadContent(CustomPlansText[title].link)}
                top={`calc(100% / 7 * ${index + 1})`}
                spanlabels={CustomPlansText[title].title}
                icon={<Info />}
              />
            ))}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          display={{ xs: "none", sm: "block" }}
          sx={{
            paddingLeft: { md: "24px" },
            paddingRight: { xs: "24px", md: "0" },
          }}
        >
          <div style={{ maxWidth: "375px", minWidth: "300px" }}>
            <Typography
              variant="caption"
              sx={{
                color: "#9aa2ae",
                fontFamily: "Economica",
                lineHeight: { xs: "19.7px", md: "22px" },
              }}
            >
              {Object.keys(CustomPlansText).map((title, index) => (
                <span key={`customText${index}`}>
                  {CustomPlansText[title].header}
                  {CustomPlansText[title].body}
                </span>
              ))}
            </Typography>
          </div>
        </Grid>
        <Grid item xs md={1} />
        <Grid item xs sm={4} />
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            textAlign: "center",
            paddingRight: "10px",
            paddingTop: { xs: 0, sm: "20px" },
          }}
        >
          <StyledButton component={Link} to="/requestquote" variant="contained">
            <InsideSpans>
              Request
              <br /> Quote
            </InsideSpans>
          </StyledButton>
        </Grid>
        <Grid item xs sm={4} />
        <Dialog
          TransitionComponent={Transition}
          fullScreen
          open={this.state.informationDialogOpen}
          onClose={this.closeDialog}
          sx={{ marginRight: "30px" }}
        >
          <DialogContent sx={{ paddingTop: "30px" }}>
            <IconButton onClick={this.closeDialog}>
              <ArrowBack />
            </IconButton>
            <br />
            <Typography
              variant="caption"
              sx={{
                color: "#9aa2ae",
                fontFamily: "Economica",
                lineHeight: { xs: "19.7px", md: "22px" },
              }}
            >
              {this.state.informationDialogHTML}
            </Typography>
            <StyledButton onClick={this.closeDialog} variant="contained">
              <InsideSpans>Back</InsideSpans>
            </StyledButton>
          </DialogContent>
        </Dialog>
      </Grid>
    );
  }
}

export default CustomPlans;
