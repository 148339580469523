import React from "react";

import { Tooltip, Grid, Typography, Link, AppBar } from "@mui/material";
import { Instagram, Facebook, Phone, Place } from "@mui/icons-material";

const CustomLink = (props) => {
  const { title, ...others } = props;
  return (
    <Tooltip title={title} placement="top">
      <a {...others} style={{ textDecoration: "none", color: "inherit" }}>
        {props.children}
      </a>
    </Tooltip>
  );
};

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <AppBar
        position="relative"
        sx={{
          backgroundColor: "#262626",
          top: "auto",
          bottom: 0,
          color: "#9aa2ae",
          zIndex: 9002,
          width: `${this.props.sizing.vw}px`,
        }}
      >
        <Grid
          container
          spacing={0}
          direction="column"
          sx={{
            height: { xs: "25px", sm: "auto" },
          }}
        >
          <Grid
            sx={{
              display: { xs: "none", sm: "flex" },
              justifyContent: "center",
              alignItems: "end",
            }}
          >
            <Typography
              sx={{
                height: { xs: 0, sm: "45px" },
              }}
            />
            <CustomLink title="1-801-839-5568" href="tel:+1-801-839-5568">
              <Phone />
            </CustomLink>
            &nbsp;
            <CustomLink
              title="Maps"
              target="_blank"
              href="https://www.google.com/maps/place/Modline+Design/@40.9811014,-111.8901551,17z/data=!3m1!4b1!4m5!3m4!1s0x8752ff632539f637:0xece39623c5162e23!8m2!3d40.9810974!4d-111.8879664"
            >
              <Place />
            </CustomLink>
            &nbsp;
            <CustomLink
              title="Instagram"
              target="_blank"
              href="https://www.instagram.com/modline_design"
            >
              <Instagram />
            </CustomLink>
            &nbsp;
            <CustomLink
              title="Facebook"
              target="_blank"
              href="https://www.facebook.com/mldplanning/"
            >
              <Facebook />
            </CustomLink>
          </Grid>
          <Grid
            item
            xs
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                display: "flex",
                justifyContent: "center",
                fontSize: { xs: "12px", sm: "inherit" },
              }}
            >
              Copyright&nbsp;©&nbsp;
              <Link color="inherit" href="https://modelinedesign.com/">
                Modline Design
              </Link>
              &nbsp;|&nbsp;2022.
            </Typography>
          </Grid>
        </Grid>
      </AppBar>
    );
  }
}

export default Footer;
