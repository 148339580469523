import React from "react";
import { Paper, Button, Tooltip, Backdrop } from "@mui/material";
import { Link } from "react-router-dom";
import Menu from "@mui/icons-material/Menu";
import Close from "@mui/icons-material/Close";
import AttributionIcon from "@mui/icons-material/Attribution";
import PanoramaIcon from "@mui/icons-material/Panorama";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import AddHomeIcon from "@mui/icons-material/AddHome";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { Facebook, Instagram, Phone, Place } from "@mui/icons-material";

const TooltipLink = (props) => (
  <Link {...props} style={{ textDecoration: "none", color: "white" }}>
    {props.children}
  </Link>
);

const navItems = [
  { label: "Gallery", icon: <PanoramaIcon fontSize="small" />, to: "/gallery" },
  {
    // label: "Services",
    label: "Custom Plans",
    icon: <AddHomeIcon fontSize="small" />,
    to: "/customplans",
    // to: "/services"
  },
  // {
  //   label: "Pricing",
  //   icon: <AttachMoneyIcon fontSize="small" />,
  //   to: "/pricing",
  // },
  {
    label: "Request Quote",
    icon: <RequestQuoteIcon fontSize="small" />,
    to: "/requestquote",
  },
  // {
  //   label: "About Us",
  //   icon: <AttributionIcon fontSize="small" />,
  //   to: "/aboutus",
  // },
];

const NavButton = (props) => {
  return (
    <Tooltip
      placement="left"
      open={Boolean(props.label)}
      title={
        props.label ? (
          <TooltipLink onClick={props.onClick} to={props.to}>
            {props.label}
          </TooltipLink>
        ) : (
          ""
        )
      }
      componentsProps={{
        popper: {
          sx: { marginRight: 0, zIndex: 9001 },
        },
        tooltip: {
          sx: {
            borderRadius: 0,
            margin: "0 !important",
            backgroundColor: "#5B7195",
          },
        },
        arrow: {
          sx: {
            opacity: 1,
            color: "#5B7195",
          },
        },
      }}
      arrow
      onClick={props.onClick}
    >
      <Button
        sx={{
          backgroundColor: "black",
          position: "absolute",
          top: props.top,
          left: "auto",
          right: props.right,
          borderRadius: props.borderRadius,
          height: props.height,
          width: props.width,
          minWidth: props.width,
          minHeight: props.height,
        }}
        size="small"
        variant="contained"
        onClick={props.onClick}
        component={
          Boolean(props.to) ? Link : Boolean(props.href) ? "a" : Button
        }
        target={props.target ? props.target : "_self"}
        to={props.to}
        href={props.href}
      >
        {props.icon}
      </Button>
    </Tooltip>
  );
};

class MobileAppBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { menuOpen: false, backdropOpen: false };
  }

  triggerMenu = () => {
    if (this.state.menuOpen) {
      this.setState({ backdropOpen: false });
      enableBodyScroll(document);
    } else {
      this.setState({ backdropOpen: true });
      disableBodyScroll(document);
    }
    this.setState({ menuOpen: !this.state.menuOpen });
  };

  render() {
    return (
      <Paper
        square
        sx={{
          position: "fixed",
          width: "30px",
          height: `${this.props.sizing.vh}px`,
          backgroundColor: "#4b4b4b",
          left: "auto",
          right: 0,
          top: 0,
          opacity: 0.8,
          zIndex: 9001,
          display: { xs: "block", sm: "none" },
        }}
      >
        <Paper
          square
          display={{ xs: "block", sm: "none" }}
          sx={{
            position: "absolute",
            top: `${0.5 * this.props.sizing.vh - 27}px`,
            left: "auto",
            right: "-5px",
            backgroundColor: "#4b4b4b",
            opacity: 0.8,
            borderRadius: "50%",
            height: "55px",
            width: "55px",
          }}
        />
        <NavButton
          borderRadius="50%"
          height="45px"
          width="45px"
          top={`${0.5 * this.props.sizing.vh - 22.5}px`}
          right="1px"
          onClick={this.triggerMenu}
          icon={
            this.state.menuOpen ? (
              <Close fontSize="small" />
            ) : (
              <Menu fontSize="small" />
            )
          }
        />
        {this.state.menuOpen &&
          navItems.map((item, index) => {
            const radius = 90;
            const offset = Math.PI / 3;
            const xOffset = 15;
            const yOffset = -20;
            const angle = (((5 / 6) * Math.PI) / navItems.length) * index;
            let x = Math.ceil(radius * Math.cos(angle - offset));
            let y = Math.ceil(radius * Math.sin(angle - offset));
            return (
              <NavButton
                borderRadius="0 50% 50% 0"
                label={item.label}
                height="35px"
                width="35px"
                right={`${x - xOffset}px`}
                top={`${0.5 * this.props.sizing.vh + y + yOffset}px`}
                icon={item.icon}
                key={`Button${index}`}
                to={item.to}
                onClick={this.triggerMenu}
              />
            );
          })}
        <NavButton
          height="25px"
          width="25px"
          top={`${this.props.sizing.vh - 150}px`}
          right="1px"
          icon={<Phone fontSize="15px" />}
          borderRadius="50%"
          href="tel:+1-801-839-5568"
        />
        <NavButton
          height="25px"
          width="25px"
          top={`${this.props.sizing.vh - 120}px`}
          right="1px"
          icon={<Place fontSize="15px" />}
          borderRadius="50%"
          target="_blank"
          href="https://www.google.com/maps/place/Modline+Design/@40.9811014,-111.8901551,17z/data=!3m1!4b1!4m5!3m4!1s0x8752ff632539f637:0xece39623c5162e23!8m2!3d40.9810974!4d-111.8879664"
        />
        <NavButton
          height="25px"
          width="25px"
          top={`${this.props.sizing.vh - 90}px`}
          right="1px"
          icon={<Instagram fontSize="15px" />}
          borderRadius="50%"
          target="_blank"
          href="https://www.instagram.com/modline_design"
        />
        <NavButton
          height="25px"
          width="25px"
          top={`${this.props.sizing.vh - 60}px`}
          right="1px"
          icon={<Facebook fontSize="15px" />}
          borderRadius="50%"
          target="_blank"
          href="https://www.facebook.com/mldplanning/"
        />
        <Backdrop
          open={this.state.backdropOpen}
          sx={{ zIndex: -1 }}
          onClick={this.triggerMenu}
        />
      </Paper>
    );
  }
}

export default MobileAppBar;
