import React from "react";

import { Grid, Box, Dialog, CircularProgress } from "@mui/material";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import BackendAxios from "../BackendAxios";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Autoplay } from "swiper";
import { StyledButton } from "../HomePage/index";
import "swiper/css";
import "swiper/css/free-mode";

let columnsPerRow = 2;
let rowsPerSlide = 4;

const imageSize = {
  width: (isPhone, sizing) =>
    Math.round(
      isPhone
        ? sizing.vw - sizing.xs.usedWidth
        : ((sizing.vh - sizing.sm.usedHeight) * 3) / 4
    ),
  height: (isPhone, sizing) =>
    Math.round(
      isPhone
        ? ((sizing.vw - sizing.xs.usedWidth) * 4) / 3
        : sizing.vh - sizing.sm.usedHeight
    ),
};

const GCD = (a, b) => {
  if (b === 0) {
    return a;
  }
  return GCD(b, a % b);
};

const aspectRatio = (h, w) => {
  const gcd = GCD(w, h);
  return { width: w / gcd, height: h / gcd };
};

const NavButton = (props) => (
  <StyledButton
    sx={{
      position: "absolute",
      zIndex: "9000",
      borderRadius: "50%",
      color: "white",
      opacity: ".5",
      backgroundColor: "#262626",
      minHeight: 0,
      minWidth: 0,
      height: props.isPhone ? 32 : 64,
      width: props.isPhone ? 32 : 64,
      padding: props.isPhone ? "6px 16px" : "8px 20px",
      left: props.left || "auto",
      right: props.right || "auto",
      top: props.top,
      direction: false,
    }}
    onMouseOver={props.onmouseover}
    onMouseLeave={props.onmouseleave}
    onClick={props.onClick}
    variant="contained"
    className={props.class}
  >
    {props.children}
  </StyledButton>
);

class Gallery extends React.Component {
  constructor(props) {
    super(props);
    const galleryFormat = [
      [
        [1, 3, 0, 1],
        [1, 0, 0, 1],
      ],
      [
        [2, 1, 3, 0],
        [2, 1, 0, 0],
      ],
      [
        [1, 3, 0, 1],
        [1, 0, 0, 1],
      ],
      [
        [3, 0, 2, 1],
        [0, 0, 2, 1],
      ],
    ];
    this.state = {
      loadedAll: false,
      page: 1,
      galleryImages: [],
      allImages: [],
      galleryFormat,
      loadedImages: [],
      go: false,
      isPhone: props.sizing.vw < 600,
      dialogOpen: false,
      dialogImage: null,
      loadingImages: false,
      totalPhotos: null,
      lowRendered: null,
      highRendered: null,
    };
    this.swiper = React.createRef();
  }

  handleResize = async () => {
    let { isPhone } = this.state;
    const { sizing } = this.props;
    if (isPhone !== sizing.vw < 600) {
      await this.setState({
        isPhone: sizing.vw < 600,
      });
      this.swiper.allowTouchMove = sizing.vw < 600;
    }
    let newImages = this.formatImages(this.state.allImages);
    await this.setState({ galleryImages: newImages });
    this.swiper.update();
  };

  componentDidMount() {
    this.getImages().then((galleryImages) => {
      this.setState({ galleryImages });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.sizing !== prevProps.sizing) {
      this.handleResize();
    }
  }

  closeDialog = () => {
    this.setState({ dialogOpen: false, dialogImage: null });
  };

  generateFormat = () => {
    const currentFormat = [];
    for (let i = 0; i < columnsPerRow; i++) {
      currentFormat.push([]);
      for (let j = 0; j < rowsPerSlide; j++) {
        currentFormat[i].push(0);
      }
    }
    return currentFormat;
  };

  getImages = async () => {
    let galleryImages = await BackendAxios.get(
      `/files/gallery?page=${this.state.page}`
    ).then((res) => {
      this.setState({ totalPhotos: res.data.count });
      return res.data.entries;
    });
    this.setState({ allImages: galleryImages, page: 2 });
    return this.formatImages(galleryImages);
  };

  formatImages = (galleryImages) => {
    const { sizing } = this.props;
    const { galleryFormat, isPhone } = this.state;
    galleryImages = JSON.parse(JSON.stringify(galleryImages));
    const currentFormat = [];
    const slicedArray = [];
    const tallArray = [];
    for (
      let s = 0;
      slicedArray.reduce((arr, curr) => arr + curr.length, 0) <
      galleryImages.length -
        (galleryImages.length % (columnsPerRow * rowsPerSlide));
      s++
    ) {
      // Inside a Slide
      const slideImages = [];
      currentFormat.push(this.generateFormat());
      for (
        let c = 0;
        columnsPerRow * rowsPerSlide >
          currentFormat[s].reduce(
            (col, cur) => col + cur.reduce((row, curr) => row + curr),
            0
          ) &&
        !(
          slicedArray.reduce((arr, curr) => arr + curr.length, 0) +
            slideImages.length >
          galleryImages.length
        );
        c++
      ) {
        // Inside a Column
        for (
          let r = 0;
          rowsPerSlide >
            currentFormat[s][c].reduce((row, cur) => row + cur, 0) &&
          !(
            slicedArray.reduce((arr, curr) => arr + curr.length, 0) +
              slideImages.length >
            galleryImages.length
          );
          r++
        ) {
          // Inside a Row
          if (currentFormat[s][c][r] === 0) {
            // Spot isn't already taken
            const startPosition =
              slicedArray.reduce((arr, curr) => arr + curr.length, 0) +
              slideImages.length;

            const acceptFormat = galleryFormat[s % 4];
            if (acceptFormat[c][r] === 2 && tallArray.length !== 0) {
              // Check for saved tall images
              const tallImage = tallArray.pop()[0];
              if (document.getElementById(tallImage.name)) {
                const aspectR = this.loadedImage(tallImage);
                tallImage.rows = aspectR.height;
                tallImage.cols = aspectR.width;
                tallImage.left = `${
                  (imageSize.width(isPhone, sizing) * (c % columnsPerRow)) /
                  columnsPerRow
                }px`;
                tallImage.top = `${
                  (imageSize.height(isPhone, sizing) * (r % rowsPerSlide)) /
                  rowsPerSlide
                }px`;
                tallImage.height = `${
                  (imageSize.height(isPhone, sizing) * aspectR.height) /
                  rowsPerSlide
                }px`;
                tallImage.width = `${
                  (imageSize.width(isPhone, sizing) / columnsPerRow) *
                  aspectR.width
                }px`;
              }
              slideImages.push(tallImage);
              galleryImages.slice(startPosition, 0, tallImage);
              currentFormat[s][c][r] = 1;
              currentFormat[s][c][r + 1] = 1;
            } else {
              // s = Slide, r = Row, c = Column
              const galleryImage = {
                ...galleryImages[startPosition],
              };
              let aspectR = {};
              if (document.getElementById(galleryImage.name)) {
                aspectR = this.loadedImage(galleryImage);
                galleryImage.rows = aspectR.height;
                galleryImage.cols = aspectR.width;
                galleryImage.left = `${
                  (imageSize.width(isPhone, sizing) * (c % columnsPerRow)) /
                  columnsPerRow
                }px`;
                galleryImage.top = `${
                  (imageSize.height(isPhone, sizing) * (r % rowsPerSlide)) /
                  rowsPerSlide
                }px`;
                galleryImage.height = `${
                  (imageSize.height(isPhone, sizing) * aspectR.height) /
                  rowsPerSlide
                }px`;
                galleryImage.width = `${
                  (imageSize.width(isPhone, sizing) / columnsPerRow) *
                  aspectR.width
                }px`;
              }
              let rowsNeeded = aspectR.height || 1;
              let columnsNeeded = aspectR.width || 1;
              if (acceptFormat[c][r] === 1 && rowsNeeded === 1) {
                // Accepts 3x2 Ratio
                slideImages.push(galleryImage);
                currentFormat[s][c][r] = 1;
              } else if (acceptFormat[c][r] === 2 && rowsNeeded === 1) {
                // Accepts 3x2 Ratio
                slideImages.push(galleryImage);
                currentFormat[s][c][r] = 1;
              } else if (
                acceptFormat[c][r] === 2 &&
                rowsNeeded === 2 &&
                columnsNeeded === 1
              ) {
                // Accepts 3x4 Ratio
                slideImages.push(galleryImage);
                currentFormat[s][c][r] = 1;
                currentFormat[s][c][r + 1] = 1;
              } else if (
                acceptFormat[c][r] === 3 &&
                ((rowsNeeded === 1 && columnsNeeded === 1) ||
                  (rowsNeeded === 2 && columnsNeeded === 2))
              ) {
                // Accepts normal image 3x2 but will double its size
                galleryImage.rows = 2;
                galleryImage.cols = 2;
                galleryImage.height = `${
                  (imageSize.height(isPhone, sizing) * 2) / rowsPerSlide
                }px`;
                galleryImage.width = `${
                  (imageSize.width(isPhone, sizing) / columnsPerRow) * 2
                }px`;
                slideImages.push(galleryImage);
                currentFormat[s][c][r] = 1;
                currentFormat[s][c][r + 1] = 1;
                currentFormat[s][c + 1][r] = 1;
                currentFormat[s][c + 1][r + 1] = 1;
              } else {
                // Image doesn't fit
                tallArray.push({
                  rows: rowsNeeded,
                  cols: columnsNeeded,
                  ...galleryImages.splice(startPosition, 1),
                });
                r--;
              }
            }
          } else {
            continue;
          }
        }
      }
      slicedArray.push(slideImages);
    }
    return slicedArray;
  };

  triggerSlide = (reverse, go) => () => {
    this.setState({ direction: reverse, go });
    if (!go) {
      this.swiper.autoplay.stop();
    } else if (go && !this.swiper.autoplay.running) {
      this.swiper.autoplay.start();
    }
  };

  clickTriggerSlide = (reverse) => () => {
    if (reverse) {
      this.swiper.slidePrev(0);
    } else {
      this.swiper.slideNext(0);
    }
  };

  setImage = (image) => () => {
    const clickedImage = this.state.allImages.findIndex(
      (x) => x.name === image.name
    );
    this.setState({ dialogImage: clickedImage, dialogOpen: true });
  };

  calibrateChunk = (images) => {
    return images.map((image, index) => {
      return (
        <Box
          onClick={this.setImage(image)}
          key={`image${index}`}
          sx={{
            cursor: "pointer",
            position: "absolute",
            left: image.left || "0",
            top: image.top || "0",
            height: image.height,
            width: image.width,
          }}
        >
          <img
            draggable="false"
            alt={image.name}
            id={image.name}
            src={`data:image/jpeg;base64,${image.thumbnail}`}
            style={{
              display: image.left ? "block" : "none",
              height: `calc(100%)`,
              width: `calc(100%)`,
            }}
            onLoad={this.loadedAnImage(image)}
          />
        </Box>
      );
    });
  };

  loadedAnImage = (image) => () => {
    let { loadedImages, allImages, galleryImages } = this.state;
    if (
      loadedImages.length >=
        galleryImages.reduce((arr, cur) => [...arr, ...cur], []).length &&
      loadedImages.indexOf(image.name) === -1
    ) {
      if (loadedImages.indexOf(image.name) === -1) {
        loadedImages.push(image.name);
        this.setState({ loadedImages });
      }
      const newImages = this.formatImages(allImages);
      this.setState({ galleryImages: newImages });
    } else {
      if (loadedImages.indexOf(image.name) === -1) {
        loadedImages.push(image.name);
        this.setState({ loadedImages });
      }
    }
  };

  loadedImage = (image) => {
    let htmlImage = document.getElementById(image.name);
    const aspectR = aspectRatio(
      htmlImage.naturalHeight,
      htmlImage.naturalWidth
    );
    // Defaults for images with incorrect aspects
    if (aspectR.width > 4 || aspectR.height > 8) {
      if (aspectR.width > aspectR.height) {
        aspectR.width = 1;
        aspectR.height = 1;
      } else if (aspectR.width < aspectR.height) {
        aspectR.width = 1;
        aspectR.height = 2;
      }
    } else {
      if (aspectR.width > aspectR.height) {
        aspectR.width =
          aspectR.width % 3 === 0
            ? aspectR.width / 3
            : aspectR.width % 2 === 0
            ? aspectR.width / 2
            : 1;
        aspectR.height =
          aspectR.height % 2 === 0
            ? aspectR.height / 2
            : aspectR.height % 3 === 0
            ? aspectR.height / 3
            : 1;
      } else if (aspectR.width < aspectR.height) {
        aspectR.width =
          aspectR.width % 3 === 0
            ? aspectR.width / 3
            : aspectR.width % 2 === 0
            ? aspectR.width / 2
            : 1;
        aspectR.height =
          aspectR.height % 2 === 0
            ? aspectR.height / 2
            : aspectR.height % 3 === 0
            ? (aspectR.height / 3) * 2
            : 1;
      }
    }
    return aspectR;
  };

  loadMore = async (direction) => {
    if (
      this.state.allImages.length > 0 &&
      this.state.allImages.length >= this.state.totalPhotos
    ) {
      if (!this.state.loadedAll) {
        this.setState({ loadedAll: true });
      }
      return;
    }
    if (this.state.allImages.length > 0 && !this.state.loadingImages) {
      this.setState({ loadingImages: true });
      let newImages = await BackendAxios.get(
        `/files/gallery?page=${this.state.page}`
      ).then((res) => {
        return res.data.entries;
      });
      let allImages;
      if (direction === "forward") {
        allImages = [...this.state.allImages, ...newImages];
      } else {
        allImages = [...newImages, ...this.state.allImages];
      }
      const galleryImages = this.formatImages(allImages);
      this.setState({ allImages, page: this.state.page + 1 });
      this.setState({
        galleryImages,
        loadingImages: false,
      });
    }
  };

  slideChange = (highRendered, lowRendered, slidesPerView) => (slide) => {
    if (
      highRendered - slidesPerView < slide.activeIndex &&
      !this.state.loadedAll
    ) {
      this.loadMore("forward");
    } else if (
      lowRendered + slidesPerView > slide.activeIndex &&
      !this.state.loadedAll
    ) {
      this.loadMore("backward");
    }
  };

  navigateImage = (forward) => () => {
    const { dialogImage } = this.state;
    this.setState({ dialogImage: forward ? dialogImage + 1 : dialogImage - 1 });
  };

  render() {
    const { galleryImages, isPhone } = this.state;
    const { sizing } = this.props;
    const emptySlides = [];
    let lowRendered = 0,
      highRendered = 0;
    const halfwayPoint = Math.floor(this.state.totalPhotos / 5 / 2);
    const slidesPerView = isPhone
      ? (sizing.vh - sizing.xs.usedHeight) /
        (((sizing.vw - sizing.xs.usedWidth) * 4) / 3)
      : (sizing.vw - sizing.sm.usedWidth) /
        (((sizing.vh - sizing.sm.usedHeight) * 3) / 4);
    for (let i = 0; i < Math.floor(this.state.totalPhotos / 5); i++) {
      emptySlides.push([]);
    }
    let addOrMinus = 1;
    for (let i = 0; i < galleryImages.length; i++) {
      if (i === 0) {
        emptySlides[halfwayPoint] = galleryImages[i];
      } else if (i % 2 === 0) {
        emptySlides[halfwayPoint + addOrMinus] = galleryImages[i];
        highRendered = halfwayPoint + addOrMinus;
        addOrMinus++;
      } else {
        emptySlides[halfwayPoint - addOrMinus] = galleryImages[i];
        lowRendered = halfwayPoint - addOrMinus;
      }
    }
    return (
      <Grid
        item
        xs={12}
        sx={{
          height: "calc(100%)",
        }}
      >
        <Box
          sx={{
            width: {
              xs: `${sizing.vw - sizing.xs.usedWidth}px`,
              sm: `${sizing.vw - sizing.sm.usedWidth}px`,
            },
            height: {
              xs: `${sizing.vh - sizing.xs.usedHeight}px`,
              sm: `${sizing.vh - sizing.sm.usedHeight}px`,
            },
          }}
        >
          {!this.state.isPhone &&
            this.state.totalPhotos &&
            this.state.dialogImage === null && (
              <div>
                <NavButton
                  left="auto"
                  right="10px"
                  top="50%"
                  class={"nextButton"}
                  onmouseover={this.triggerSlide(false, true)}
                  onmouseleave={this.triggerSlide(false, false)}
                  onClick={this.clickTriggerSlide(false)}
                >
                  <ArrowForwardIos size={isPhone ? "small" : "medium"} />
                </NavButton>
                <NavButton
                  onmouseover={this.triggerSlide(true, true)}
                  onmouseleave={this.triggerSlide(true, false)}
                  onClick={this.clickTriggerSlide(true)}
                  left="10px"
                  top="50%"
                  class={"prevButton"}
                >
                  <ArrowBackIosNew />
                </NavButton>
              </div>
            )}
          {this.state.totalPhotos && (
            <Swiper
              loopPreventsSlide={false}
              onSlideChange={this.slideChange(
                highRendered,
                lowRendered,
                slidesPerView
              )}
              initialSlide={halfwayPoint - slidesPerView / 2}
              simulateTouch={this.state.isPhone}
              allowTouchMove={this.state.isPhone}
              direction={this.state.isPhone ? "vertical" : "horizontal"}
              navigation={{
                nextEl: ".nextButton",
                prevEl: ".prevButton",
              }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
                stopOnLastSlide: false,
                reverseDirection: this.state.direction,
                waitForTransition: false,
              }}
              loop={true}
              speed={1990}
              slidesPerView={slidesPerView}
              spaceBetween={0}
              freeMode={{
                enabled: true,
                momentum: this.state.isPhone,
              }}
              modules={[FreeMode, Autoplay]}
              onAfterInit={(swiper) => {
                this.swiper = swiper;
                swiper.autoplay.stop();
              }}
            >
              {emptySlides.map((images, index) => {
                return (
                  <SwiperSlide key={`slide${index}`}>
                    {this.calibrateChunk(images)}
                    {images.length < 1 && <CircularProgress />}
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
        </Box>
        <Dialog
          onClose={this.closeDialog}
          open={this.state.dialogOpen}
          maxWidth={false}
          PaperProps={{
            sx: {
              height: this.state.isPhone
                ? `auto`
                : `${sizing.vh - sizing.sm.usedHeight}px`,
              padding: isPhone ? "4px" : "8px",
            },
          }}
          sx={{ zIndex: 9002 }}
        >
          {this.state.dialogImage !== null && (
            <img
              alt={this.state.allImages[this.state.dialogImage].name}
              id={this.state.allImages[this.state.dialogImage].name}
              src={`data:image/jpeg;base64,${
                this.state.allImages[this.state.dialogImage].thumbnail
              }`}
              style={{
                height: this.isPhone ? "auto" : `calc(100%)`,
                width: this.isPhone ? "calc(100%)" : `auto`,
              }}
            />
          )}
          <div>
            <NavButton
              left="auto"
              right="10px"
              top="calc(50% - 16px)"
              onClick={this.navigateImage(true)}
              isPhone={isPhone}
            >
              <ArrowForwardIos size={isPhone ? "small" : "medium"} />
            </NavButton>
            <NavButton
              left="10px"
              top="calc(50% - 16px)"
              onClick={this.navigateImage(false)}
              isPhone={isPhone}
            >
              <ArrowBackIosNew size={isPhone ? "small" : "medium"} />
            </NavButton>
          </div>
        </Dialog>
      </Grid>
    );
  }
}

export default Gallery;
